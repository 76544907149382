import { AmLich } from "./AmLich";
import Devextreme from "@sonphat/devextreme";

const AutoFocusErrorHandlers = [
    (wrapper) => {
        let input = undefined;
        if ((input = wrapper.querySelector("input.dx-texteditor-input"))) {
            input.focus();
            return true;
        }
    },
    (wrapper) => {
        let input = wrapper.querySelector("input,text-area,select");
        if (input) {
            input.focus();
            return true;
        }
    },
];

export default function Helper($Core, emitter) {
    Devextreme.$Core = $Core;
    this.autoFocusError = (result) => {
        if (
            result.brokenRules &&
            result.brokenRules.length &&
            result.brokenRules[0].validator &&
            result.brokenRules[0].validator._$element &&
            result.brokenRules[0].validator._$element.length
        ) {
            let wrapper = result.brokenRules[0].validator._$element[0];

            for (let AutoFocusErrorHandler of AutoFocusErrorHandlers) {
                if (AutoFocusErrorHandler && AutoFocusErrorHandler(wrapper)) {
                    break;
                }
            }
        }
    };

    this.getLunarDate = (dd, mm, yy) => {
        return AmLich.getLunarDate(dd, mm, yy);
    };
    this.getLunarDateText = (dd, mm, yy) => {
        let data = AmLich.getLunarDate(dd, mm, yy);
        let day = data.day < 10 ? "0" + data.day : data.day;
        let month = data.month < 10 ? "0" + data.month : data.month;
        return day + "/" + month;
    };

    this.getYearCanChiText = (yy) => {
        return AmLich.getYearCanChi(yy);
    };
    this.formatDateTime = (dateTime) => {
        if (dateTime) {
            var tmp = new Date(dateTime);
            var HH = tmp.getHours();
            var mm = tmp.getMinutes();
            var dd = tmp.getDate();
            var MM = tmp.getMonth() + 1;
            HH < 10 ? (HH = "0" + HH) : HH;
            mm < 10 ? (mm = "0" + mm) : mm;
            dd < 10 ? (dd = "0" + dd) : dd;
            MM < 10 ? (MM = "0" + MM) : MM;
            var yyyy = tmp.getFullYear();
            return HH + ":" + mm + " " + dd + "/" + MM + "/" + yyyy;
        } else {
            return "";
        }
    };
    this.formatDate = (dateTime) => {
        if (dateTime) {
            var tmp = new Date(dateTime);
            var dd = tmp.getDate();
            var MM = tmp.getMonth() + 1;
            dd < 10 ? (dd = "0" + dd) : dd;
            MM < 10 ? (MM = "0" + MM) : MM;
            var yyyy = tmp.getFullYear();
            return dd + "/" + MM + "/" + yyyy;
        } else {
            return "";
        }
    };
    this.formatTime = (dateTime) => {
        if (dateTime) {
            var tmp = new Date(dateTime);
            var HH = tmp.getHours();
            var mm = tmp.getMinutes();
            HH < 10 ? (HH = "0" + HH) : HH;
            mm < 10 ? (mm = "0" + mm) : mm;
            return HH + ":" + mm;
        } else {
            return "";
        }
    };

    this.getToday = () => {
        let date = new Date();
        let utc = Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0
        );
        return new Date(utc);
    };

    this.getEndDay = (date) => {
        let tmpDate = new Date(date);
        return new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            23,
            59,
            59
        );
    };

    this.getStartDay = (date) => {
        let tmpDate = new Date(date);
        return new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            0
        );
    };

    this.getEndMonth = (date) => {
        let tmpDate = date ? new Date(date) : new Date();
        return new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth() + 1,
            0,
            23,
            59,
            59
        );
    };

    this.getStartMonth = (date) => {
        let tmpDate = date ? new Date(date) : new Date();
        return new Date(tmpDate.getFullYear(), tmpDate.getMonth(), 1, 0);
    };

    this.getStartDateString = (date) => {
        let tmpDate = date ? new Date(date) : new Date();
        let utc = new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            0
        );
        return `${new Date(utc).toISOString()}`;
    };

    this.getEndDateString = (date) => {
        let tmpDate = date ? new Date(date) : new Date();
        let utc = Date.UTC(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            16,
            59,
            59
        );
        return `${new Date(utc).toISOString()}`;
    };

    this.getTimeString = (date) => {
        let tmpDate = date ? new Date(date) : new Date();
        let HH = tmpDate.getHours();
        let mm = tmpDate.getMinutes();
        HH = HH < 10 ? "0" + HH : HH;
        mm = mm < 10 ? "0" + mm : mm;
        return `${HH}:${mm}`;
    };

    this.getUTCDateFromTimeString = (timeString) => {
        let time = timeString ? timeString.split(":") : ["0", "0"];
        let tmpDate = new Date();
        let date = new Date(
            tmpDate.getFullYear(),
            tmpDate.getMonth(),
            tmpDate.getDate(),
            time[0],
            time[1],
            0
        );
        return date.toISOString();
    };

    this.Table = (options_table) => {
        return Devextreme.table(options_table);
    };
    this.Excel = (options) => {
        return Devextreme.excel(options);
    };

    this.FormatTextLichChay = (x, { Tuan, ThangDuong, ThangAm }) => {
        switch (x.LoaiLichChay) {
            case ThangDuong:
                return `Lịch dương - ${x.CacNgayChayTheoLich.join(", ")}`;
            case ThangAm:
                return `Lịch âm - ${x.CacNgayChayTheoLich.join(", ")}`;
            case Tuan:
                return (
                    `Lịch tuần - ` +
                    x.CacNgayChayTheoLich.sort()
                        .map((t) => (t == 1 ? "Chủ nhật" : `Thứ ${t}`))
                        .join(", ")
                );
        }
        return "Chưa lập kế hoạch cho Nốt/Tài";
    };

    this.formatPhone = (phoneNumber) => {
        let count = 0;
        let time = 0;
        let str = "";
        let tmpPhone = phoneNumber + "";
        tmpPhone = tmpPhone.trim();
        tmpPhone = tmpPhone.replaceAll(" ", "");
        if (tmpPhone.length < 9) {
            return tmpPhone;
        } else {
            for (let i = tmpPhone.length; i > 0; i--) {
                count++;
                if (count % 3 == 0 && time < 2) {
                    time++;
                    str = " " + tmpPhone[i - 1] + str;
                } else {
                    str = tmpPhone[i - 1] + str;
                }
            }
            return str;
        }
    };

    this.KiemTraQuyen = (type, name) => {
        let DanhSachQuyen =
            $Core.Auth.TokenParsed &&
            $Core.Auth.TokenParsed.resource_access &&
            $Core.Auth.TokenParsed.resource_access[type] &&
            $Core.Auth.TokenParsed.resource_access[type].roles
                ? $Core.Auth.TokenParsed.resource_access[type].roles
                : [];
        return DanhSachQuyen.some((e) => e == name);
    };

    this.BrowserViewLink = (href, filename, x) => {
        let a = document.createElement("a");
        a.target = "_blank";
        a.download = filename;
        a.href = href;
        a.click();
    };

    this.BrowserDownloadLink = function BrowserDownloadLink(href, filename, x) {
        // filename = filename || "Lệnh điện tử.pdf";

        // console.log(filename, href, x);

        // let a = document.createElement("a");
        // // a.target = "_blank";
        // a.download = filename;
        // a.href = href;
        // console.log(
        //     "🚀 ~ file: Helper.js ~ line 222 ~ BrowserDownloadLink ~ a",
        //     a
        // );
        // a.click();

        var url = href;

        //Create XMLHTTP Request.
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "blob";
        req.onload = function () {
            //Convert the Byte Data to BLOB object.
            var blob = new Blob([req.response], {
                type: "application/octetstream",
            });

            //Check the Browser type and download the File.
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(blob, filename);
            } else {
                var url = window.URL || window.webkitURL;
                let link = url.createObjectURL(blob);
                var a = document.createElement("a");
                a.setAttribute("download", filename);
                a.setAttribute("href", link);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        };
        req.send();
    };

    this.ThongBaoToast = (type, msg) => {
        let ParamsError = {
            isVisible: true,
            message: msg,
            type: type,
        };
        emitter.emit("onToast", ParamsError);
    };

    this.ThongBaoPopup = ({
        type,
        message_title,
        message,
        title,
        textButton,
    }) => {
        let ParamsError = {
            type: type || "Error",
            title: title || "Thông báo",
            message_title: message_title || "Lỗi!",
            message: message || "",
            textButton: textButton || "Đồng ý",
        };
        emitter.emit("ThongBaoPopup", ParamsError);
    };

    this.Common = Devextreme;
    this.DevExDataSource = Devextreme.DevExDataSource;
    this.DevExDataSourceCustom = Devextreme.DevExDataSourceCustom;
    this.FilterRowWhenEnter = Devextreme.FilterRowWhenEnter;
}
