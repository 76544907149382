<template>
    <div id="root">
        <div :class="cssClasses">
            <component
                :is="$route.meta.layout"
                :is-x-small="screen.getScreenSizeInfo.isXSmall"
                :is-large="screen.getScreenSizeInfo.isLarge"
            >
                <div>
                    <router-view></router-view>
                </div>
            </component>
        </div>
    </div>

    <DxPopup
        v-if="ifPopupThongBao"
        v-model:visible="PopupThongBao"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        :width="440"
        height="auto"
        @hidden="onHidden('PopupThongBao')"
    >
        <NotificationVue
            :Params="ParamsThongBao"
            :ButtonLeft="false"
            @click="XacNhanThongBao"
        />
    </DxPopup>
</template>

<script>
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { getCurrentInstance, reactive, onMounted, onBeforeUnmount, computed } from "vue";
import { DxPopup } from "devextreme-vue";
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";

function getScreenSizeInfo() {
    const screenSizes = sizes();

    return {
        isXSmall: screenSizes["screen-x-small"],
        isLarge: screenSizes["screen-large"],
        cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
    };
}

export default {
    setup() {
        const vm = getCurrentInstance();

        const title = vm.proxy.$appInfo.title;
        const screen = reactive({ getScreenSizeInfo: {} });
        screen.getScreenSizeInfo = getScreenSizeInfo();

        function screenSizeChanged() {
            screen.getScreenSizeInfo = getScreenSizeInfo();
        }

        onMounted(() => {
            subscribe(screenSizeChanged);
        });

        onBeforeUnmount(() => {
            unsubscribe(screenSizeChanged);
        });

        const cssClasses = computed(() => {
            return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
        });

        return {
            title,
            screen,
            cssClasses,
        };
    },
    components: { DxPopup, NotificationVue },
    data() {
        return {
            checkLoading: 0,
            ifPopupThongBao: false,
            PopupThongBao: false,
            ParamsThongBao: {
                state: "Error",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
                title: "Cảnh báo!",
                message_title: "Bạn muốn thoát khỏi hệ thống?",
            },
        };
    },
    watch: {
        checkLoading() {
            if (this.checkLoading == 0) {
                this.$hideLoading;
            }
            if (this.checkLoading > 0) {
                this.$showLoading;
            }
            if (this.checkLoading < 0) {
                this.checkLoading = 0;
            }
        },
    },
    methods: {
        onHidden(name) {
            this["if" + name] = false;
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        XacNhanThongBao(bool) {
            if (bool == true) {
                this.PopupThongBao = false;
            }
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("startLoading", () => {
            self.checkLoading++;
        });
        this.emitter.on("stopLoading", () => {
            self.checkLoading--;
        });
        this.emitter.on(
            "ThongBaoPopup",
            ({
                type = "error",
                title = "Thông báo",
                message_title = "",
                message = "",
                textButton = "Đồng ý",
            }) => {
                self.ParamsThongBao.state = type;
                self.ParamsThongBao.button_save = textButton;
                self.ParamsThongBao.title = title;
                self.ParamsThongBao.message_title = message_title;
                self.ParamsThongBao.message = message;
                self.showPopup("PopupThongBao");
            }
        );
    },
};
</script>

<style lang="scss">
html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

.app {
    @import "./themes/generated/variables.base.scss";
    background-color: darken($base-bg, 5);
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}
</style>