export default {
    namespaced: true,
    state: () => ({
        DanhSachLoaiHoaDon: [],
        DanhSachKyHieu: [],

        DanhSachKieuHoaDon: [],
        DanhSachTuyen: [],
        DanhSachLoaiDonHang: [],
        DanhSachLoaiHoaDonDoanhNghiepSuDung: [],
        DanhSachTaiKhoanHoaDonDienTu: [],
    }),

    mutations: {
        DanhSachLoaiHoaDon: (state, data) => {
            state.DanhSachLoaiHoaDon = data;
        },
        DanhSachKyHieu: (state, data) => {
            state.DanhSachKyHieu = data;
        },
        DanhSachLoaiDonHang: (state, data) => {
            state.DanhSachLoaiDonHang = data;
        },
        DanhSachKieuHoaDon: (state, data) => {
            state.DanhSachKieuHoaDon = data;
        },
        DanhSachTuyen: (state, data) => {
            state.DanhSachTuyen = data;
        },
        DanhSachLoaiHoaDonDoanhNghiepSuDung: (state, data) => {
            state.DanhSachLoaiHoaDonDoanhNghiepSuDung = data;
        },
    },
    actions: {
        async LayDanhSachLoaiHoaDon({ commit }) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.DanhSachLoaiHoaDon")
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    commit("DanhSachLoaiHoaDon", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachKieuHoaDon({ commit }) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.DanhSachKieuHoaDon")
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    commit("DanhSachKieuHoaDon", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachLoaiDonHang({ commit }) {
            try {
                let resp = await this.$Core.Api.DonHang(
                    this.$i18n.global.t("urlCHDDT.LayDanhSachLoaiDonHang")
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0 || resp.Data.errorCode === 1002) {
                    commit("DanhSachLoaiDonHang", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.DanhSachKyHieu"),
                    data
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0 || resp.Data.errorCode === 1002) {
                    commit("DanhSachKyHieu", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
        async LayDanhSachLoaiHoaDonDoanhNghiepSuDung({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t(
                        "urlCHDDT.DanhSachLoaiHoaDonDoanhNghiepSuDung"
                    ),
                    data
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0 || resp.Data.errorCode === 1002) {
                    commit("DanhSachLoaiHoaDonDoanhNghiepSuDung", result);
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async ThemKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.ThemKyHieuHoaDonDienTu")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async SuaKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.SuaKyHieuHoaDonDienTu")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async XoaKyHieu({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.XoaKyHieuHoaDonDienTu")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async CapNhatLoaiHoaDon({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.CapNhatLoaiHoaDon")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async XoaLoaiHoaDon({ commit }, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t("urlCHDDT.XoaLoaiHoaDon")
                ).Post(data);

                let result = resp.Data.data;
                if (resp.Data.errorCode === 0) {
                    // commit("DanhSachKyHieu",result)
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },

        async LayDanhSachTaiKhoanHoaDonDienTu(store, data) {
            try {
                let resp = await this.$Core.Api.CongHoaDonDienTu(
                    this.$i18n.global.t(
                        "urlCHDDT.LayDanhSachCauHinhTaiKhoanHoaDonDienTu"
                    ),
                    data
                ).Get();

                let result = resp.Data.data;
                if (resp.Data.status) {
                    store.state.DanhSachTaiKhoanHoaDonDienTu = result;
                }
                return resp.Data || [];
            } catch (ex) {
                if (ex.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw ex;
            }
        },
    },
};
