import { createRouter, createWebHistory } from "vue-router";
import none from "./layouts/none";

import Home from "./views/home";
import menuApplication from "./layouts/menu-application";

function loadView(view) {
    return () => import(`./views/${view}.vue`);
}
function loadViewIndex(view) {
    return () => import(`./views/${view}/index.vue`);
}

export default function ($Core, $i18n, $Helper) {
    const router = new createRouter({
        routes: [
            {
                path: "/",
                name: "home",
                meta: {
                    requiresAuth: true,
                    layout: menuApplication,
                },
                component: Home,
            },
            {
                path: "/Ky-Hieu-Hoa-Don",
                name: "DanhSachKyHieu",
                meta: {
                    requiresAuth: "CauHinhKyHieuHoaDon",
                    layout: menuApplication,
                },
                component: loadViewIndex("Ky-Hieu-Hoa-Don"),
            },
            {
                path: "/Ky-Hieu-Hoa-Don/Them-Sua-Ky-Hieu",
                name: "Them-Sua-Ky-Hieu",
                meta: {
                    requiresAuth: "ThemSuaKyHieuHoaDon",
                    layout: menuApplication,
                },
                component: loadViewIndex("Ky-Hieu-Hoa-Don/Them-Sua-Ky-Hieu"),
            },

            {
                path: "/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung",
                name: "Loai-Hoa-Don-Doanh-Nghiep-Su-Dung",
                meta: {
                    requiresAuth: "CauHinhLoaiHoaDonDoanhNghiepSuDung",
                    layout: menuApplication,
                },
                component: loadViewIndex("Loai-Hoa-Don-Doanh-Nghiep-Su-Dung"),
            },
            {
                path: "/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung/Cap-Nhat-Loai-Hoa-Don",
                name: "Cap-Nhat-Loai-Hoa-Don",
                meta: {
                    requiresAuth: "ThemSuaLoaiHoaDonDoanhNghiepSuDung",
                    layout: menuApplication,
                },
                component: loadViewIndex(
                    "Loai-Hoa-Don-Doanh-Nghiep-Su-Dung/Cap-Nhat-Loai-Hoa-Don"
                ),
            },
            {
                path: "/Tai-Khoan-Hoa-Don-Dien-Tu",
                name: "Tai-Khoan-Hoa-Don-Dien-Tu",
                meta: {
                    requiresAuth: "CauHinhLoaiHoaDonDoanhNghiepSuDung",
                    layout: menuApplication,
                },
                component: loadViewIndex("Tai-Khoan-Hoa-Don-Dien-Tu"),
            },
            {
                path: "/Error",
                name: "Error",
                meta: {
                    requiresAuth: true,
                    layout: none,
                    title: "Lỗi!",
                },
                component: loadView("Error"),
            },
            {
                path: "/",
                redirect: "/home",
            },
            {
                path: "/recovery",
                redirect: "/home",
            },
            {
                path: "/:pathMatch(.*)*",
                redirect: "/home",
            },
        ],
        history: createWebHistory(),
    });

    router.beforeEach((to, from, next) => {
        console.log("abcd", $Core);
        if (to.name === "login-form" && $Core.Auth.IsAuth) {
            next({ name: "home" });
        }
        if (!$Core.Auth.IsAuth) {
            $Core.Auth.Login();
            // next({
            //     name: "login-form",
            //     query: { redirect: to.fullPath }
            // });
        } else {
            if (to.meta.requiresAuth == true || to.meta.requiresAuth == false) {
                next();
            } else {
                if (
                    $Helper.Common.CheckRole(
                        $i18n.global.t("Quyen.CauHinh"),
                        $i18n.global.t("Quyen." + to.meta.requiresAuth)
                    )
                ) {
                    next();
                } else {
                    next({
                        name: "Error",
                        query: {
                            statusCode: 403,
                        },
                    });
                }
            }
        }
    });
    return router;
}
