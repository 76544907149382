<template>
    <Application
        :mynav="menuNav"
        @DangXuat="DangXuat"
        PhienBan="0.0.211005.1"
        :propModelToast="modelToast"
    >
        <template v-slot:default>
            <slot />
        </template>
    </Application>
</template>

<script>
import Application from "@sonphat/common-v1/components/application.vue";

export default {
    components: { Application },
    data() {
        return {
            menuNav: [
                {
                    id: "Home",
                    text: "Trang chủ",
                    icon: "home",
                    to: "/",
                    expanded: false,
                },

                // QLTT
                {
                    id: "CHDDT",
                    text: "Cổng hóa đơn điện tử",
                    icon: "",
                    // external: true,
                    expanded: false,
                },
                {
                    id: "Ky-Hieu-Hoa-Don",
                    parent: "CHDDT",
                    text: "Ký hiệu",
                    icon: "",
                    to: "/Ky-Hieu-Hoa-Don",
                    expanded: false,
                    role: "CauHinhKyHieuHoaDon",
                },
                {
                    id: "Loai-Hoa-Don-Doanh-Nghiep-Su-Dung",
                    parent: "CHDDT",
                    text: "Loại hóa đơn bến xe sử dụng",
                    icon: "",
                    to: "/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung",
                    expanded: false,
                    role: "CauHinhLoaiHoaDonDoanhNghiepSuDung",
                },
                {
                    id: "",
                    parent: "CHDDT",
                    text: "Tài khoản hóa đơn điện tử",
                    icon: "",
                    to: "/Tai-Khoan-Hoa-Don-Dien-Tu",
                    expanded: false,
                    role: "CauHinhTaiKhoanHoaDonDienTu",
                },
                // {
                //     id: this.$t("namePath.QuanLyLuongTuyen"),
                //     parent: "QLTT",
                //     text: this.$t("namePath.QuanLyLuongTuyen"),
                //     icon: "",
                //     to: "/Quan-Ly-Luong-Tuyen",
                //     expanded: false,
                // },
                // {
                //     id: this.$t("namePath.QuanLyPhuongTien"),
                //     parent: "QLTT",
                //     text: this.$t("namePath.QuanLyPhuongTien"),
                //     icon: "",
                //     to: "/Quan-Ly-Phuong-Tien",
                //     expanded: false,
                // },
            ],
            openState: false,
            modelToast: {
                isVisible: false,
                message: "",
                type: "success",
            },
        };
    },
    methods: {
        DangXuat() {
            this.$Core.Auth.Logout();
        },
        mapMenu() {
            this.menuNav.map((e) => {
                if (!e.role) {
                    e.visible = true;
                } else {
                    e.visible = this.$Helper.Common.CheckRole(
                        this.$t("Quyen.CauHinh"),
                        this.$t("Quyen." + e.role)
                    );
                }
                return e;
            });
        },
    },
    created() {
        this.mapMenu();
    },
    mounted() {
        let self = this;
        this.emitter.on("onToast", (params) => {
            self.modelToast = params;
        });
    },
};
</script>

<style scoped>
::v-deep .icon-toast .dx-icon {
    font-size: 36px;
}
::v-deep .icon-toast {
    position: relative;
    left: -16px;
}
</style>
